<template>
  <div class="admin">
    <Nav :displayName="displayName" @toggle-side="toggleSideMenu"/>

    <!-- <sidebar></sidebar> -->
    <SideBar class="side" :showSide="showSide"/>

    <div class="main-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <transition
              class="site-transition"
              enter-active-class="animated fadeIn faster"
              leave-active-class="animated fadeOut faster"
            >
              <router-view class="view"></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Nav from "@/components/admin/AdminNav.vue";
import SideBar from "@/components/admin/SideBar.vue";

export default {
  name: "admin",
  components: {
    Nav,
    SideBar
  },
  data: function() {
    return {
      displayName: null,
      showSide: false
    };
  },
  created() {
    // this.getUser();
  },
  methods: {
    async getUser() {
      try {

        let user = await axios({
          method: "get",
          url: "http://testdeployd.aezypay.com/users/me",
          withCredentials: true,
        })
        .then(function(res) {
          
        })
        .catch(function(error) {
          console.log(error);
        });

      } catch (error) {
        console.error("error", error);
      }
    },
    toggleSideMenu() {
      this.showSide = !this.showSide;
    }
  }
};
</script>

<style lang="scss" scoped>

body {
  overflow: unset;
}

.admin {
  margin-left: -15px;
  margin-right: -15px;

  position: relative;

  .main-content {
    margin-top: 103.802px;
    margin-left: 90px;
    // width: calc(100vw - 90px);
  }

  .side {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    // height: calc(100vh-98px);
    height: 100%;
    min-height: calc(100vh - 98px);
    z-index: 1;
  }
  .col-12 {
    padding: 0;
    min-height: calc(100vh - 98px);
  }
}
</style>
